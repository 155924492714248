<template>
  <jc-form class="jc-datashow-list jc-datashow-list-inline">
    <jc-row :gutter="20">
      <jc-col :span="8">
        <jc-form-item label="主办单位全称：">
          <p>
            {{ mainInfoData && mainInfoData.organizersName | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位性质：">
          <p>
            {{ mainInfoData && mainInfoData.organizersNature | organizersNatureFilter(organizersNatureOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位证件类型：">
          <p>
            {{ mainInfoData && mainInfoData.identityType | identityTypeFilter(identityTypeOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位证件号码：">
          <p>{{ mainInfoData && mainInfoData.identityNo | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位所属区域：">
          <p>
            {{
              mainInfoData &&
                `${mainInfoData.provinceName}${mainInfoData.cityName}${mainInfoData.countyName}` | limiteStr(50)
            }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位证件地址：">
          <p>
            {{ mainInfoData && mainInfoData.indentityAddress | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="主办单位通信地址：">
          <p>
            {{ mainInfoData && mainInfoData.detailAddress | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="投资人或主管名称：">
          <p>{{ mainInfoData && mainInfoData.investorName | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="负责人姓名：">
          <p>{{ mainInfoData && mainInfoData.ownerName | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="负责人证件类型：">
          <p>
            {{ mainInfoData && mainInfoData.ownerIdentityType | identityTypeFilter(identityTypeOptions) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="负责人证件号码：">
          <p>
            {{ mainInfoData && mainInfoData.ownerIdentityNo | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8" v-if="mainInfoData && mainInfoData.ownerIdentityNoValidityPeriod">
        <jc-form-item label="负责人证件有效期：">
          <p>
            {{ mainInfoData && mainInfoData.ownerIdentityNoValidityPeriod }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="办公室电话：">
          <p>{{ mainInfoData && mainInfoData.phone | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="手机号码：">
          <p>{{ mainInfoData && mainInfoData.mobile | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="应急电话号码：">
          <p>
            {{ mainInfoData && mainInfoData.emergencyCall | limiteStr(50) }}
          </p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="电子邮件地址：">
          <p>{{ mainInfoData && mainInfoData.email | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
      <jc-col :span="8">
        <jc-form-item label="备注：">
          <p>{{ mainInfoData && mainInfoData.remark | limiteStr(50) }}</p>
        </jc-form-item>
      </jc-col>
    </jc-row>
  </jc-form>
</template>
<script>
// import { filterArray } from '@/utils'
import profileMixins from '@/mixins/profile.mixins.js'
import ValidityPeriod from '@/components/validity-period'

export default {
  props: {
    infoData: Object
  },
  components: {
    ValidityPeriod
  },
  mixins: [profileMixins],
  data() {
    return {}
  },
  watch: {},
  computed: {
    mainInfoData() {
      if (Object.keys(this.infoData).length > 0) return this.infoData.principalInfo
    }
  },
  mounted() {},
  created() {},
  filters: {},
  methods: {}
}
</script>
